import React, { useState } from "react";
import './parallaxStyle.css'

const Parallax = () => {
    return (
        <div className="w-full h-auto parallax xxs:object-contain xxs:bg-local">

        </div>
    )
}

export default Parallax;