import React, { useState } from "react";
import './scrollForm.css'

const ScrollForm = () => {
    return (
        <div className="scrollForm xxs:object-contain xxs:bg-local">

        </div>
    )
} 

export default ScrollForm;